import "../scss/style.scss";
(function () {
    const setSize = 360;
    const viewport = document.querySelector("meta[name='viewport']");
    function adjustViewport() {
        const value = window.outerWidth > setSize ? "width=device-width,initial-scale=1" : "width=" + setSize;
        if ((viewport === null || viewport === void 0 ? void 0 : viewport.getAttribute("content")) !== value) {
            viewport === null || viewport === void 0 ? void 0 : viewport.setAttribute("content", value);
        }
    }
    addEventListener("resize", adjustViewport);
    adjustViewport();
})();
let mediaFlag = "";
const mediaQueryTablet = window.matchMedia("(min-width: 768px) and (max-width: 1024px)");
const mediaQueryMobile = window.matchMedia("(max-width: 767px)");
const mediaQueryPC = window.matchMedia("(min-width: 1025px)");
function mediaCheckMobile(event) {
    if (event.matches) {
        mediaFlag = "mobile";
    }
}
function mediaCheckTablet(event) {
    if (event.matches) {
        mediaFlag = "tablet";
    }
}
function mediaCheckPC(event) {
    if (event.matches) {
        mediaFlag = "pc";
    }
}
mediaQueryMobile.addEventListener("change", mediaCheckMobile);
mediaQueryTablet.addEventListener("change", mediaCheckTablet);
mediaQueryPC.addEventListener("change", mediaCheckPC);
mediaCheckMobile(mediaQueryMobile);
mediaCheckTablet(mediaQueryTablet);
mediaCheckPC(mediaQueryPC);
document.addEventListener("DOMContentLoaded", function () {
    setUpAccordion();
});
const setUpAccordion = function () {
    const details = document.querySelectorAll(".js-details");
    const isRunning = "running";
    const isOpend = "is-opened";
    details.forEach(function (element) {
        const summary = element.querySelector(".js-summary");
        const content = element.querySelector(".js-content");
        summary === null || summary === void 0 ? void 0 : summary.addEventListener("click", function (event) {
            event.preventDefault();
            if (element.dataset.animStatus === isRunning) {
                return;
            }
            if (element.getAttribute("open")) {
                element.classList.toggle(isOpend);
                const closingAnim = content.animate(closingAnimKeyframes(content), animTiming);
                element.dataset.animStatus = isRunning;
                closingAnim.onfinish = function () {
                    element.removeAttribute("open");
                    element.dataset.animStatus = "";
                };
            }
            else {
                element.setAttribute("open", "true");
                element.classList.toggle(isOpend);
                const openingAnim = content.animate(openingAnimKeyframes(content), animTiming);
                element.dataset.animStatus = isRunning;
                openingAnim.onfinish = function () {
                    element.dataset.animStatus = "";
                };
            }
        });
    });
};
const animTiming = {
    duration: 400,
    easing: "ease-out",
};
const closingAnimKeyframes = (content) => [
    {
        height: content.offsetHeight + "px",
        opacity: 1,
    },
    {
        height: 0,
        opacity: 0,
    },
];
const openingAnimKeyframes = (content) => [
    {
        height: 0,
        opacity: 0,
    },
    {
        height: content.offsetHeight + "px",
        opacity: 1,
    },
];
const pageTop = document.querySelector(".js-pageTop");
pageTop === null || pageTop === void 0 ? void 0 : pageTop.addEventListener("click", function (event) {
    event.preventDefault();
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
});
